// comment to trigger build
/* eslint-disable camelcase */
import styled from '@emotion/styled'
import { Layout } from '@leshen/gatsby-theme-contentful'
import React from 'react'

import {
  Banner,
  Brandy,
  Image,
  LeshenPhoneCTA,
  List,
  ListItem,
  PackageCard,
  Price,
  SplitContent,
  SVGImage,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'

import { Column, Columns, Dropdown, Typography } from '@leshen/ui'
import { graphql, navigate } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'

const StateTemplate = ({ data }) => {
  const { Meta_Description, State, Promo_Code, Page_Path, Meta_Title } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const cityDropdownData = data.cities.edges
    .map((current) => ({
      label: current.node.City,
      value: current.node.Page_Path,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <SplitContent
            backgroundColor="dark"
            alignImageToBottom
            image={
              <Image
                data={data.heroImage.cloudinary[0].gatsbyImageData}
                alt={data.heroImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h1">Get Frontier&reg; Internet</Typography>
                <Typography variant="h4">in {State_Name}</Typography>
                <Price
                  standalone
                  className="price embedded-entry"
                  {...data.heroPrice?.price}
                  bullets={data?.heroPrice?.bullets}
                  variant="Default"
                />
                <List>
                  <ListItem>
                    <Typography>
                      Amazon eero Wi-Fi Pro 6 router included<sup>**</sup>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>No data caps or overage charges</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Month-to-month pricing with no annual commitment
                    </Typography>
                  </ListItem>
                </List>
                <br />
                <br />
                <LeshenPhoneCTA variant="hero" color="primary">
                  Call
                </LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Shop for Frontier Internet plans available in {State_Name}
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            customLink={null}
          >
            <Columns
              className=""
              leftAligned={false}
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <PackageCard
                label={false}
                packageData={data.package1}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />

              <PackageCard
                label={false}
                packageData={data.package2}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />

              <PackageCard
                label={false}
                packageData={data.package3}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />
            </Columns>
          </VariableContent>
          <Banner
            backgroundColor="primary"
            layout="100"
            centerAligned="true"
            mainContent={
              <>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  Check availability by city
                </Typography>
                <Typography variant="h5" style={{ textAlign: 'center' }}>
                  Find the best Internet deals in your area:
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Shop for Frontier Internet plans available in {State_Name}
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            customLink={null}
          >
            <Columns
              className=""
              leftAligned={false}
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <Column
                backgroundColor="white"
                borderColor="none"
                borderType="none"
                image={
                  <SVGImage
                    svg={data.noContract.cloudinary[0].svg.code}
                    presentationWidth={
                      data.noContract.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
              >
                <Typography variant="h5">No Annual Commitment</Typography>
                <Typography variant="">
                  Never be locked into a plan—that&apos;s the plan. Not having
                  annual contracts is part of the freedom you&apos;ll experience
                  with Frontier.
                </Typography>
              </Column>
              <Column
                backgroundColor="white"
                borderColor="none"
                borderType="none"
                image={
                  <SVGImage
                    svg={data.wifi.cloudinary[0].svg.code}
                    presentationWidth={
                      data.wifi.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
              >
                <Typography variant="h5">
                  Wireless Network Capability
                </Typography>
                <Typography variant="">
                  An Amazon eero Wi-Fi router included.<sup>**</sup> Experience
                  the freedom to connect wirelessly in your home.
                </Typography>
              </Column>
              <Column
                backgroundColor="white"
                borderColor="none"
                borderType="none"
                image={
                  <SVGImage
                    svg={data.speed.cloudinary[0].svg.code}
                    presentationWidth={
                      data.speed.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
              >
                <Typography variant="h5">No Data Caps</Typography>
                <Typography variant="">
                  Enjoy endless fun online. With unlimited monthly data, you can
                  connect as much as you want, when you want with no overage
                  charges.
                </Typography>
              </Column>
            </Columns>
          </VariableContent>
          <VariableContent
            backgroundColor="dark"
            mainContent={
              <>
                <Typography variant="h2">
                  Extend your Wi-Fi signal starting at $10/mo<sup>♦</sup>
                </Typography>
                <Typography>
                  Eliminate dead spots by adding Whole-Home Wi-Fi extenders to
                  your plan.
                </Typography>
                <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
              </>
            }
            alignMainContent="Left"
          />
          <SplitContent
            backgroundColor=""
            alignImageToBottom
            image={
              <Image
                data={data.phone.cloudinary[0].gatsbyImageData}
                alt={data.phone.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.phoneMobile.cloudinary[0].gatsbyImageData}
                alt={data.phoneMobile.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Save big with Frontier bundles in {State_Name}
                </Typography>
                <Typography>
                  Get all the home services you need with a bundled plan from
                  Frontier. When you sign up for Frontier phone and internet in{' '}
                  {State_Name}, you’ll get both services on one bill for one low
                  price. Here are a few of the benefits you look forward to with
                  a bundled package from Frontier:
                </Typography>
                <List>
                  <ListItem>
                    <Typography>Unlimited nationwide calling</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Unlimited monthly data</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Free 24/7 tech support</Typography>
                  </ListItem>
                </List>
                <br />
                <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            backgroundColor=""
            mainContent={
              <>
                <Brandy
                  symbol={data.disclaimerOne.symbol}
                  text={data.disclaimerOne.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerTwo.symbol}
                  text={data.disclaimerTwo.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerThree.symbol}
                  text={data.disclaimerThree.text}
                  variant="legal"
                />
              </>
            }
            alignMainContent="Left"
          />
        </>
      }
    />
  )
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery($pagePath: String!, $stateAbbreviation: String!) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerFrontierbundlesAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "9f079251-dcfd-5307-993f-a204c2991dab" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "2f67302f-2fd4-55d3-8805-d6330dd58187" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
    footerDisclaimers: allContentfulTemplate(
      filter: { name: { eq: "Default" } }
    ) {
      edges {
        node {
          footerDisclaimers {
            brandy {
              text
              symbol
            }
          }
        }
      }
    }
    cities: allDatasetManagerFrontierbundlesAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    noContract: contentfulMedia(
      contentful_id: { eq: "7iHp8UjsZbypM4RaX5VagD" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    wifi: contentfulMedia(contentful_id: { eq: "59ssrlbELTgR4uhRsUkWyp" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    speed: contentfulMedia(contentful_id: { eq: "3EbULXl8dkZ6zcWTmMFeCk" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    heroImage: contentfulMedia(
      contentful_id: { eq: "3jjN46RLszRN4O1SgIagy9" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    ladyTabletMobile: contentfulMedia(
      contentful_id: { eq: "4xFuu4x9jo21kvzX7O56JS" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    phone: contentfulMedia(contentful_id: { eq: "264aNICaC50Jw6uh87xOkJ" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    phoneMobile: contentfulMedia(
      contentful_id: { eq: "3EWyu3ec3lOo5QMom3D1eq" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroPrice: brandyPackage(brandy_id: { eq: "ftr-sigil-internet-hero" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package1: brandyPackage(brandy_id: { eq: "ftr-sigil-internet" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package2: brandyPackage(brandy_id: { eq: "ftr-sigil-homephone" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package3: brandyPackage(brandy_id: { eq: "ftr-sigil-frontiersecure" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    disclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-copperfooter-1" }
    ) {
      id
      text
      symbol
    }
    disclaimerTwo: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-wholehomewifi" }
    ) {
      id
      text
      symbol
    }
    disclaimerThree: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-eeroo" }
    ) {
      id
      text
      symbol
    }
  }
`
